<template>
  <div>
    <div v-if="imageUrl || allowEmptyImage">
      <div
        class="studio-header-content embed-responsive-header ratio"
        :class="{
          'studio-header-content--profile-photo': profilePhoto,
        }"
      >
        <img
          class="studio-header-image darkable-img embed-responsive-item"
          v-if="imageUrl"
          :src="imageUrl"
          :style="studioHeaderImageStyle"
        />
        <img
          class="profile-photo profile-photo--float"
          :class="`profile-photo--${profilePosition}`"
          :src="profilePhoto"
          v-if="profilePhoto"
        />
        <div class="overlay-content">
          <slot></slot>
          <slot name="extra"></slot>
        </div>
      </div>
    </div>
    <div v-else-if="profilePhoto">
      <img class="profile-photo" :src="profilePhoto" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HMStudioHeader',
  props: {
    image: {
      type: Object,
    },
    profilePhoto: {
      type: String,
    },
    allowEmptyImage: {
      type: Boolean,
      default: false,
    },
    profilePosition: {
      type: String,
      default: 'float',
    },
  },
  computed: {
    imageUrl() {
      return this.image?.image_url;
    },
    studioHeaderImageStyle() {
      if (!this.image) {
        return {};
      }
      const { focal_point_x, focal_point_y } = this.image;
      return {
        objectPosition: `${focal_point_x}% ${focal_point_y}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.col-12 {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.studio-header {
  padding: 0;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    height: 220px;
  }

  &--profile-photo {
    margin-bottom: 60px;
  }
}

.studio-header-content {
  position: relative;
  max-height: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &--profile-photo {
    margin-bottom: 40px;
  }
}

.studio-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }
  @include media-breakpoint-up(sm) {
    border-radius: $border-radius;
  }
}

.cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.profile-photo {
  width: 140px;
  height: 140px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 50%;
  border: 5px solid white;
  background: white;
  &--float {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: -40px - 5px;
    z-index: 1;
    top: auto;
  }
  &--right {
    position: absolute;
    left: calc(100% - 70px);
    bottom: -40px - 5px;
    z-index: 1;
    top: auto;
  }

  @include media-breakpoint-down(sm) {
    width: 80px;
    height: 80px;
  }
}
.inner-container {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 2rem;
}

.embed-responsive-header::before {
  padding-top: 56.25%;

  @include media-breakpoint-down(xl) {
    padding-top: 31.25%;
  }
}

.overlay-content {
  z-index: 2;
  display: flex;
  justify-content: center;
}
</style>
